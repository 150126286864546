<template>

    <div class="position-relative icono">
        <div class="position-absolute tip border-radius-5 shadow white-text font-600"
             style="top: -30px">
            {{text}}
        </div>

        <img :height="height" :src="src" />
    </div>

</template>

<script>


    export default {
        components: {},

        props: ['src', 'text', 'height'],

        name: 'IconTooltip',

        data() {
            return {}
        }
    };
</script>

<style scoped>

    .tip{
        visibility: hidden;
        opacity: 0;
        padding: 5px 10px;
        font-size: 12px;
        white-space: nowrap;
        background-color: rgb(68, 68, 68);
        transition: ease 200ms;
    }

    .icono:hover .tip{
        opacity: 1;
        visibility: visible;
    }

    .icono:active .tip{
        opacity: 1;
        visibility: visible;
    }

</style>