<template>
  <div class="h-100 grey lighten-5" style="min-height: 100vh">
    <AllLayout v-if="$route.meta.layout === 'all-layout'">
      <router-view/>
    </AllLayout>
    <SiteLayout v-else-if="$route.meta.layout === 'site-layout'">
      {{$route.meta.layout}}
      <router-view/>
    </SiteLayout>
  </div>
</template>

<style lang="scss" rel="stylesheet/scss"></style>

<script>

import AllLayout from "@/layouts/AllLayout";
import SiteLayout from "@/layouts/SiteLayout";

export default {
  name: 'App',

  components: {SiteLayout, AllLayout},

  computed: {
    sitio() {
      return this.$store.getters['sitio/sitio'];
    }
  }
};

</script>