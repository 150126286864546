<template>

    <div class="p-3 grey lighten-5">
        <router-view />
    </div>

</template>

<script>
    export default {
        name: 'AllLayout',

        computed: {},

        methods:{},

        created() {}
    }
</script>

<style scoped>

</style>