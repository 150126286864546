import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MenuView from "@/views/MenuView";
import AllView from "@/views/AllView";

const routes = [
    {
        path: '/',
        name: 'all',
        component: AllView,
        meta: {
            layout: 'all-layout'
        }
    },
    {
        path: '/:sitio',
        name: 'home',
        component: HomeView,
        meta: {
            layout: 'site-layout'
        }
    },
    {
        path: '/:sitio/menu/:id',
        name: 'menu',
        component: MenuView,
        meta: {
            layout: 'site-layout'
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
