import axios from "axios";

export const API_URL = 'https://api.sethotels.net/api/carta-digital';

export default {
    namespaced: true,
    state: {
        sitio: null,
        banderas: {
            es: 'https://sethotels.ams3.cdn.digitaloceanspaces.com/directorios/banderas/es.png',
            en: 'https://sethotels.ams3.cdn.digitaloceanspaces.com/directorios/banderas/uk.webp',
            de: 'https://sethotels.ams3.cdn.digitaloceanspaces.com/directorios/banderas/de.png',
            ca: 'https://sethotels.ams3.cdn.digitaloceanspaces.com/directorios/banderas/ca.png',
            it: 'https://sethotels.ams3.cdn.digitaloceanspaces.com/directorios/banderas/it.png',
            fr: 'https://sethotels.ams3.cdn.digitaloceanspaces.com/directorios/banderas/fr.svg'
        }
    },
    mutations: {
        SET_SITIO(state, sitio) {
            state.sitio = sitio;
        },
    },
    actions: {
        async fetchContent({commit}, sitio) {
            try {
                const resp = await axios.post(API_URL, {url: sitio, locale: localStorage.locale});
                commit('SET_SITIO', resp.data);
            } catch (error) {
                console.error('Error fetching content:', error);
            }
        },
    },
    getters: {
        sitio: (state) => state.sitio,
        banderas: (state) => state.banderas,
    },
};
