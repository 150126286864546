<template>
    <div class="azul-seth" style="max-width: 550px; margin: auto">
        <h5 class="text-center font-800 pt-3 pb-3">{{i18n.descubre}}</h5>
        <div>
            <div v-for="s in sitios" :key="s.id" class="mb-3 text-center font-600 shadow-sm border-radius-5 white">
                <router-link :to="s.url" class="text-decoration-none azul-seth">
                    <div class="p-3">
                        {{s.nombre}}
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>

    import axios from "axios";
    import {API_URL} from "@/store/sitio";

    export default {
        name: 'AllView',

        data() {
            return {
                sitios: []
            };
        },

        computed: {},

        methods: {},

        created() {
            axios.post(API_URL + '/sitios').then(resp => {
                this.sitios = resp.data;
            });
        }
    }
</script>

<style scoped>

</style>