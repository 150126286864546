<template>

    <div style="max-width: 550px; margin: auto; height: 100vh; overflow: auto">
        <router-view />
    </div>

</template>

<script>
    export default {
        name: 'SiteLayout',

        computed: {
            sitio() {
                return this.$store.getters['sitio/sitio'];
            },
        },

        methods:{},

        created() {
            if(this.sitio === null){
                this.$store.dispatch('sitio/fetchContent', this.$route.params.sitio).then(() => {
                    document.title = this.sitio.nombre;
                });
            }
        }
    }
</script>

<style scoped>

</style>