<template>

    <div v-if="sitio !== null"
         class="background-image-placement overflow-auto h-100 position-relative"
         :style="{'background-image': `url(${sitio.foto_url})`}">

        <div class="d-flex position-absolute" style="top: 10px; right: 10px" >
            <button v-for="(idioma, index) in sitio.idiomas.split(',')"
                    :key="index"
                    @click="setLocale(idioma)"
                    :style="{'background-image': `url(${$store.getters['sitio/banderas'][idioma]})`}"
                    class="me-1 cursor-pointer background-image-placement button-idioma me-2"></button>
        </div>

        <div class="w-100">
            <div style="width: 80%" class="d-none d-md-block m-auto">
                <img style="width: 100%" :src="sitio.logo_url">
            </div>
            <div style="width: 100%" class="d-block d-md-none m-auto">
                <img style="width: 100%" :src="sitio.logo_url">
            </div>
        </div>

        <div class="p-3">
            <div v-for="m in publicableMenus"
                 :key="m.id"
                 class="white p-3 text-center mb-3 border-radius-5 font-600">
                <router-link :to="`${this.$route.params.sitio}/menu/${m.id}`"
                             class="w-100 h-100 azul-seth text-decoration-none">
                    {{m.nombre}}
                </router-link>
            </div>

            <div class="white width-100 p-3 border-radius-5 position-relative" style="z-index: 999;">
                <div class="d-flex mb-3">
                    <div style="width: 70px;"><img
                            src="https://sethotels.ams3.cdn.digitaloceanspaces.com/carta-digital/icono_plato_espanol.png"
                            style="width: 42px;"></div>
                    <div>
                        <div class="font-weight-bold">Plato español</div>
                        <div class="font-weight-light">Spanish dish</div>
                    </div>
                </div>
                <div class="d-flex mb-3">
                    <div style="width: 70px;"><img
                            src="https://sethotels.ams3.cdn.digitaloceanspaces.com/carta-digital/icono_producto_local.png"
                            style="width: 42px;"></div>
                    <div>
                        <div class="font-weight-bold">Producto Local de Menorca</div>
                        <div class="font-weight-light">Menorcan Local Product</div>
                    </div>
                </div>
                <div class="d-flex mb-3">
                    <div style="width: 70px;"><img
                            src="https://sethotels.ams3.cdn.digitaloceanspaces.com/carta-digital/icono_km0.png"
                            style="width: 42px;"></div>
                    <div>
                        <div class="font-weight-bold">KM0</div>
                        <div class="font-weight-light">KM0</div>
                    </div>
                </div>
                <div class="d-flex mb-3">
                    <div style="width: 70px;"><img
                            src="https://sethotels.ams3.cdn.digitaloceanspaces.com/carta-digital/icono_menorca.png"
                            style="width: 42px;"></div>
                    <div>
                        <div class="font-weight-bold">Plato menorquín</div>
                        <div class="font-weight-light">Menorcan dish</div>
                    </div>
                </div>
                <div class="d-flex mb-3">
                    <div style="width: 70px;"><img
                            src="https://sethotels.ams3.cdn.digitaloceanspaces.com/carta-digital/icono_vegan.png"
                            style="width: 42px;"></div>
                    <div>
                        <div class="font-weight-bold">Vegetariano</div>
                        <div class="font-weight-light">Vegetarian</div>
                    </div>
                </div>
                <div class="position-relative text-right" style="height: 40px;">
                    <div class="position-absolute" style="bottom: 0px; right: 0px;">
                        <div class="font-weight-bold">IVA Incluido</div>
                        <div class="font-weight-light">VAT included</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'HomeView',

        computed: {
            sitio() {
                return this.$store.getters['sitio/sitio'];
            },
            publicableMenus() {
                return this.sitio.menus.filter((m) => m.publicable);
            }
        },

        methods:{
            setLocale(locale) {
                localStorage.locale = locale;
                location.reload();
            }
        },

        created() {}
    }
</script>

<style scoped>
    .button-idioma {
        height: 26px;
        width: 30px;
        border: none !important;
        border-radius: 15px;
        margin-bottom: 0px;
    }

    #topNav {
        width: 100%;
        backdrop-filter: blur(15px);
        background-color: rgba(240, 240, 240, 0.5)
    }

    .content-link div {
        width: 100%;
        text-decoration: none !important;
        padding: 5px 20px;
        border-radius: 15px;
    }

    .content-link:hover div {
        background-color: #f5f5f5;
        font-weight: 500;
    }

    .router-link-exact-active div {
        background-color: #f5f5f5;
        font-weight: 500;
    }
</style>