<template>

  <div v-if="sitio !== null" class="background-image-placement h-100 white-text"
       :style="{'background-image': `url(${sitio.foto_url})`}">
    <div class="w-100 h-100 overflow-auto p-3" style="background-color: rgba(0,0,0,0.2)">

      <div class="clearfix overflow-auto d-flex pb-2 mb-4" style="white-space: nowrap">
        <button class="btn btn-sm white blue-grey-text text-darken-2 float-start me-1">
          <IconArrowBack
              @click="back()"
              size=16
              stroke=2
          />
        </button>
        <button @click="categoria = null"
                class="btn btn-sm white blue-grey-text text-darken-2 float-start me-1 font-600">
          {{ i18n.todo }}
        </button>
        <button v-for="(m, index) in menu.config"
                :key="index"
                @click="categoria = m.categoria.id"
                class="btn btn-sm white blue-grey-text text-darken-2 float-start me-1 font-600">
          {{ m.categoria.nombre }}
        </button>
      </div>

      <div v-for="(m, index) in categorias" :key="index" class="mb-5">
        <div class="font-700 font-24 mb-2">
          {{ m.categoria.nombre }}
        </div>

        <div class="p-3 border-radius-5 mb-3 white shadow azul-seth">
          <div v-for="(a) in m.articulos" :key="a.id">
            <div class="d-flex mb-3">
              <div class="w-100 font-600 text-uppercase">
                {{ a.nombre }}
              </div>
              <div class="font-400 ps-3"
                   v-if="a.precio > 0"
                   style="width: fit-content; white-space: nowrap">
                {{ a.precio }} €
              </div>
            </div>
            <div class="clearfix">
              <div v-if="a.iconos !== null && typeof a.iconos === 'string'"
                   class="float-start d-flex">
                <IconTooltip
                    class="me-1"
                    v-for="i in a.iconos.split(',')"
                    :key="i"
                    :height="23"
                    :src="sitio.iconos[i].icono_url"
                    :text="sitio.iconos[i].nombre"/>
              </div>
              <div v-if="a.alergenos !== null"
                   class="float-end d-flex">
                <IconTooltip
                    class="me-1"
                    v-for="alergeno in a.alergenos"
                    :key="alergeno.id"
                    :height="23"
                    :src="alergeno.icono"
                    :text="alergeno.nombre"/>
              </div>
            </div>
            <hr/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import IconTooltip from "@/components/IconTooltip";
import IconArrowBack from "@/partials/Icons/IconArrowBack";

export default {

  name: 'HomeView',

  components: {IconArrowBack, IconTooltip},

  data() {
    return {
      categoria: null
    };
  },

  computed: {
    sitio() {
      return this.$store.getters['sitio/sitio'];
    },
    menu() {
      return this.sitio.menus.filter((m) => this.$route.params.id == m.id)[0];
    },
    categorias() {
      if (this.categoria !== null) {
        return this.menu.config.filter((m) => this.categoria == m.categoria.id);
      } else {
        return this.menu.config;
      }

    }
  },

  methods: {
    back() {
      history.back()
    }
  },

  created() {
  }

}
</script>

