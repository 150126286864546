<template>
    <BaseIcon :size="size" :stroke="stroke" :rotate="rotate">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M5 12l14 0"></path>
        <path d="M5 12l6 6"></path>
        <path d="M5 12l6 -6"></path>
    </BaseIcon>
</template>

<script>
    import BaseIcon from "./BaseIcon";

    export default {
        components: {BaseIcon},
        props: ['size', 'stroke', 'rotate'],
        name: 'IconArrowBack',
    }
</script>
